import Vue from 'vue'
import VueRouter from 'vue-router'

// 登录
import Login from '@/views/login/index'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
        //meta:{title:"溯源信息"}
    },
]

const router = new VueRouter({
    // base: '/',
    mode: 'hash',
    routes,
})

export default router
