<template>
  <div class="page">
    <div v-if="loading" class="hp-loading">
      <van-loading size="60px" vertical>加载中...</van-loading>
    </div>
    <div v-else class="inner_page">
      <img
        class="wrap_bg"
        style="
          background-size: cover;
          height: 100vh;
          overflow: hidden;
          position: relative;
        "
        :src="'https://www.miao-an.com/' + info.product_introduction.cover"
        v-if="info.product_introduction"
      />
      <!--  :src="imgUrl" -->
      <!-- <img
        class="wrap_bg"
        style="
          width: 100vw;
          height: 90vh;
          overflow: hidden;
          background-color: pink;
          position: relative;
        "
        :src="'https://www.miao-an.com/' + info.product_introduction.cover"
        v-if="info.product_introduction"
      /> -->
      <!-- && info.product_introduction -->
      <div class="syBg_box" v-show="info.product_introduction">
        <div class="syBg_contant" >
          <div class="syBg_top">
            <div>
            <div class="tips">您查询的产品为</div>
            <div class="product_name">{{ info.product_name }}</div>
          </div>
          <div>
            <img src="../../assets/img/zp.gif" style="width: 60px;height: 60px;transform:scale(2)"/>
          </div>
          </div>
          <div class="code_box">
            <div class="code_tit">追溯编码</div>
            <!-- {{ info.product_unit_code }} -->
            <div class="sy_code">{{ info.product_unit_code }}</div>
          </div>
        </div>
        <!-- <div class="circle_box">
          <img src="../../assets/img/zp.gif" style="width: 80px;height: 80px;"/>
        </div> -->
        <div class="circle_box">
          <div class="circle">
            <div class="circle_conmon">{{ info.view_count }}</div>
          </div>
          <div class="circle_text">本产品扫描查询次数</div>
        </div>
      </div>
      <div class="syBg_box" v-show="false">
        <div class="syBg_contant_planb">
          <div class="code_tit_planb">您查询的产品追溯编码为</div>
          <!-- {{ info.product_name }}-->
          <div class="code_box_planb">
            <!-- {{ info.product_unit_code }} -->
            <div class="sy_code_planb">1111111111111</div>
          </div>
        </div>
      </div>
      <!-- 图片 -->
      <!-- src="../../assets/img/bg.jpg"   :src="'https://www.miao-an.com/' + info.product_introduction.cover"
        v-if="info.product_introduction"-->
      <!-- <img
        style="width: 100vw; height: 35vh; overflow: hidden; position: absolute"
        :src="'https://www.miao-an.com/' + info.product_introduction.cover"
        v-if="info.product_introduction"
      /> -->
      <!-- 内容区 -->
      <div class="contant_box" v-show="!dialog_message">
        <div class="flag_box">
          <div class="main_tit">溯源信息</div>
          <div class="message_part">
            <!-- <div class="row_box">
              <div class="txt_l">产品名称:</div>
              <div class="txt">{{ info.product_name }}</div>
            </div> -->
            <div class="row_box">
              <div class="txt_l">批次信息:</div>
              <!-- {{ info.product_batch_name }}  -->
              <div>{{ info.product_batch_name }}</div>
            </div>
            <!-- <div class="row_box">
              <div class="txt_l">追溯编码:</div>
              <div class="txt">{{ info.product_unit_code }}</div>
            </div> -->
            <div class="row_box">
              <div class="txt_l">企业名称:</div>
              <!-- {{ info.customer_name }} -->
              <div class="txt">{{ info.customer_name }}</div>
            </div>
            <div class="row_box">
              <div class="txt_l">服务热线:</div>
              <!-- {{ info.product_phone }} -->
              <div class="txt">{{ info.product_phone }}</div>
            </div>
            <div class="row_box">
              <div class="txt_l">首次查询:</div>
              <!-- {{ getTime(info.first_view_at) }} -->
              <div class="txt">{{ getTime(info.first_view_at) }}</div>
            </div>
            <!-- <div class="row_box">
              <div class="txt_l">扫码详情:</div>
              <div class="txt">本产品为第{{ info.view_count }}次扫描查询</div>
            </div> -->
            <div
              class="row_box"
              v-for="(item, index) in productList"
              :key="index"
            >
              <div class="txt_l">{{ item.name }}:</div>
              <!-- <span class="left-label">{{ item.name }}：</span> -->
              <!-- <span class="right-label">{{ item.content }}</span> -->
              <div class="txt">{{ item.content }}</div>
            </div>
          </div>
          <!-- 菜单 -->
          <div class="contant_title_planb">
            <div class="tit_box_planb">
              <!-- <div :class="ac == 0 ? 'tit ac' : 'tit'" @click="handlerMenu(0)">
            溯源记录
          </div> -->
              <div
                :class="ac == 0 ? 'tit_planb ac_planb' : 'tit_planb'"
                @click="handlerMenu(0)"
              >
                <div>产品</div>
                <div>介绍</div>
              </div>
              <div
                :class="ac == 1 ? 'tit_planb ac_planb' : 'tit_planb'"
                @click="handlerMenu(1)"
              >
                <div>推广</div>
                <div>信息</div>
              </div>
              <div
                :class="ac == 2 ? 'tit_planb ac_planb' : 'tit_planb'"
                @click="handlerMenu(2)"
              >
                <div>资质</div>
                <div>证明</div>
              </div>
              <div :class="ac == 3 ? 'tit_planb ac_planb' : 'tit_planb'" @click="handlerMenu(3)">
                <div>溯源</div>
                <div>记录</div>
          </div>
              <!-- <div
              v-show="activityList.length > 0"
              :class="ac == 4 ? 'tit ac' : 'tit'"
              @click="handlerMenu(4)"
            >
              促销活动
            </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="common-module module2">
        <div class="info-list">
          <div>
            <span class="left-label">追溯编码：</span>
            <span style="color: red !important" class="right-label">
              {{ info.product_unit_code }}
            </span>
          </div>
          <div>
            <span class="left-label">批次：</span>
            <span class="right-label"> {{ info.product_batch_name }} </span>
          </div>
          <div>
            <span class="left-label">首次查询：</span>
            <span class="right-label">{{ getTime(info.first_view_at) }}</span>
          </div>
          <div>
            <span class="left-label">扫码详情：</span>
            <span class="right-label"
              >本产品为第
              <span style="font-size: 24px; color: red"
                >{{ info.view_count }}
              </span>
              次扫描查询</span
            >
          </div>
        </div>
      </div> -->
      <!-- <div class="common-module module1">
        <div class="info-list">
          <div>
            <span class="left-label">产品：</span>
            <span class="right-label">{{ info.product_name }}</span>
          </div>
          <div v-if="info.customer_name">
            <span class="left-label">企业名称：</span>
            <span class="right-label">{{ info.customer_name }}</span>
          </div> -->
      <!-- <div v-if="info.product_extra.ptype">
                        <span class="left-label">产品类型：</span>
                        <span class="right-label">{{ info.product_extra.ptype }}</span>
                    </div>
                    <div v-if="info.product_extra.qualityDate && info.product_extra.qualityDateUnit">
                        <span class="left-label">保质期：</span>
                        <span class="right-label">{{
                            info.product_extra.qualityDate }} {{ info.product_extra.qualityDateUnit == "month" ? "月" : "日" }}</span>
                    </div>
                    <div v-if="info.product_extra.price">
                        <span class="left-label">单价：</span>
                        <span class="right-label">{{ info.product_extra.price }}</span>
                    </div>
                    <div v-if="info.product_extra.weight">
                        <span class="left-label">重量：</span>
                        <span class="right-label">{{ info.product_extra.weight }}</span>
                    </div>
                    <div v-if="info.product_extra.specifications">
                        <span class="left-label">规格：</span>
                        <span class="right-label">{{ info.product_extra.specifications }}</span>
                    </div>
                    <div v-if="info.product_extra.area">
                        <span class="left-label">销售区域：</span>
                        <span class="right-label">{{ info.product_extra.area }}</span>
                    </div>
                    <div v-if="info.product_extra.composition">
                        <span class="left-label">成分含量：</span>
                        <span class="right-label">{{ info.product_extra.composition }}</span>
                    </div> -->
      <!-- <div v-for="(item, index) in productList" :key="index">
            <span class="left-label">{{ item.name }}：</span>
            <span class="right-label">{{ item.content }}</span>
          </div>
        </div>
      </div> -->
      <!-- <div class="common-module module2">
        <div class="info-list">
          <div>
            <span class="left-label">追溯编码：</span>
            <span class="right-label">{{ info.product_unit_code }}</span>
          </div>
          <div>
            <span class="left-label">首次查询：</span>
            <span class="right-label">{{ getTime(info.first_view_at) }}</span>
          </div>
          <div>
            <span class="left-label">扫码详情：</span>
            <span class="right-label">{{ info.scan_code_prompt }}</span>
          </div>
        </div>
      </div> -->
      <!-- <div class="common-module module3" @click="callPhone(info.product_phone)">
        <img src="../../assets/img/phone.svg" alt="" />服务热线{{
          info.product_phone
        }}
      </div> -->
      <!-- <div class="contant_title">
        <div class="tit_box">
          <div :class="ac == 0 ? 'tit ac' : 'tit'" @click="handlerMenu(0)">
            溯源信息
          </div>
          <div :class="ac == 1 ? 'tit ac' : 'tit'" @click="handlerMenu(1)">
            推广信息
          </div>
          <div :class="ac == 2 ? 'tit ac' : 'tit'" @click="handlerMenu(2)">
            资质证明
          </div> -->
      <!-- <div :class="ac == 3 ? 'tit ac' : 'tit'" @click="handlerMenu(3)">
            扫码记录
          </div> -->
      <!-- <div
            v-show="activityList.length > 0"
            :class="ac == 4 ? 'tit ac' : 'tit'"
            @click="handlerMenu(4)"
          >
            促销活动
          </div>
        </div>
      </div> -->
      <!-- :src="'https://www.miao-an.com/' + info.product_introduction.cover"
      :src="imgUrl"
      -->
      <!-- <img
        :src="imgUrl"
        style="height: 100vh; position: relative"
        @click="handleColse"
        v-show="dialog_message"
      /> -->
      <div class="contant_region" v-show="dialog_message" @click="handleColse">
        <!-- 产品介绍 && info.product_introduction -->
        <div class="region_box" v-if="ac == 0 && info.product_introduction">
          <div class="tit_close">
            <div class="dialog_tit">产品介绍</div>
            <img
              class="close_menu"
              @click="handleColse"
              src="../../assets/img/close.png"
              alt=""
            />
          </div>
          <div style="width: 100%; height: 90%; overflow-y: scroll">
            <div
              class="hp-div"
              v-html="info.product_introduction.content"
            ></div>
          </div>
        </div>
        <!-- 推广信息 -->
        <div class="region_box" v-if="ac == 1">
          <div class="tit_close">
            <div class="dialog_tit">推广信息</div>
            <img
              class="close_menu"
              @click="handleColse"
              src="../../assets/img/close.png"
              alt=""
            />
          </div>
          <div style="width: 100%; height: 90%; overflow-y: scroll">
            <div
              class="info-list"
              v-for="item in info.customer_promotions"
              :key="item.id"
            >
              <div v-if="item.name">
                <!-- <span class="left-label">名称：</span> -->
                <span class="right-label">{{ item.name }}</span>
              </div>
              <div v-if="item.content">
                <!-- <span class="left-label">内容：</span> -->
                <span class="right-label">{{ item.content }}</span>
              </div>
              <div v-if="item.code" style="display: flex;justify-content: center;">
                <!-- <span class="left-label">二维码图片链接：</span> -->
                <img class="right-img" style="width: 85%;height: 85%;margin: 15px 0;" :src="item.code" />
              </div>
            </div>
          </div>
          <!--img src="../../assets/img/down.png" class="show-more-img" :class="showPromotionsFlag ? 'show' : 'hide'" @click="showPromotions"-->
          <!-- </div> -->
        </div>
        <!-- 资质证明 -->
        <div class="region_box" v-if="ac == 2">
          <div class="tit_close">
            <div class="dialog_tit">资质证明</div>
            <img
              class="close_menu"
              @click="handleColse"
              src="../../assets/img/close.png"
              alt=""
            />
          </div>
          <div style="width: 100%; height: 90%; overflow-y: scroll">
            <div
              class="info-list"
              v-for="item in info.customer_qualifications"
              :key="item.id"
            >
              <div v-if="item.name">
                <span class="right-label">{{ item.name }}</span>
              </div>
              <div v-if="item.content" style="display: flex;justify-content: center;">
                <img class="right-img" style="width: 85%;height: 85%;margin: 15px 0;" :src="item.content" />
              </div>
            </div>
          </div>
        </div>
        <!-- 溯源记录 -->
        <div class="region_box" v-if="ac == 3">
           <div class="tit_close">
            <div class="dialog_tit">溯源记录</div>
            <img
              class="close_menu"
              @click="handleColse"
              src="../../assets/img/close.png"
              alt=""
            />
          </div>
          <div style="width: 100%; height: 90%; overflow-y: scroll">
          <div class="info-list" v-for="item in traceList" :key="item.id">
            <div>
              <span class="left-label">环节：</span>
              <span class="right-label">{{ item.type_name }}</span>
            </div>
            <div>
              <span class="left-label">内容：</span>
              <span class="right-label">{{ item.content }}</span>
            </div>
            <div v-for="(extraItem, index) in item.extra" :key="index">
              <span class="left-label">{{ extraItem.name }}：</span>
              <span class="right-label">{{ extraItem.content }}</span>
            </div>
            <div>
              <span class="left-label">时间：</span>
              <span class="right-label">{{ item.created_time }}</span>
            </div>
            <div>
              <span class="left-label">地点：</span>
              <span class="right-label">{{ item.location }}</span>
            </div>
            <div>
              <span class="left-label">IP：</span>
              <span class="right-label">{{ item.ip }}</span>
            </div>
          </div>
          </div>
          
          <!-- <div
          v-if="info.trace_info && traceList.length < info.trace_info.length"
          class="show-more-box"
        >
          <div class="show-more" @click="showMoreTrace">展示更多</div>
        </div> -->
        </div>
        <!--  促销活动 -->
        <div class="region_box" v-if="ac == 4 && activityList.length > 0">
          <div
            class="activity-list"
            v-for="item in activityList"
            :key="item.id"
          >
            <div class="title">{{ item.name }}</div>
            <div class="time">
              <van-icon name="underway-o" /> {{ getTime(item.start_time) }} ~
              {{ getTime(item.end_time) }}
            </div>
            <div class="cover" v-if="item.cover">
              <img class="right-img" :src="host + item.cover" />
            </div>
            <div class="content" v-if="item.content">
              <div v-html="item.content"></div>
            </div>
            <div class="url" v-if="item.url">
              <a :href="item.url">促销活动入口</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiCreater from "@/utils/apiCreater";
//import api from '@/api/index'
const service = apiCreater();
export default {
  data() {
    return {
      host: "https://www.miao-an.com/",
      apiHost: "https://api.miao-an.com",
      loading: true,
      product_unit_uuid: "",
      product_unit_code: "",
      type: 50,
      location: "北京",
      info: {},
      traceList: [],
      productList: [],
      showPromotionsFlag: false,
      showQualificationsFlag: false,
      showProductIntroductionFlag: false,
      imgUrl: require("@/assets/img/11.jpg"),
      activityList: [],
      showProductActivityFlag: false,
      showTraceListFlag: true,
      ac: 0,
      dialog_message: false,
      // product_batch_name: null,
    };
  },

  created() {
    document.title = "溯源信息";
    let geturl = window.location.search;
    let getqyinfo = geturl.split("?")[1];
    let getqys = new URLSearchParams("?" + getqyinfo);
    this.product_unit_uuid = getqys.get("uuid");
    this.product_unit_code = getqys.get("code");
    this.type = getqys.get("type") ? Number(getqys.get("type")) : 50;
  },
  mounted() {
    this.getLocation(this.product_unit_uuid, this.product_unit_code, this.type);
    // let id = this.info.product_batch_id;
    // service
    //   .get("v1/batch/get", {
    //     id,
    //   })
    //   .then((res) => {
    //     this.product_batch_name = res.data.name;
    //   });
  },
  methods: {
    handleColse() {
      this.dialog_message = false;
    },
    //菜单切换
    handlerMenu(index) {
      this.dialog_message = true;
      this.ac = index;
    },
    getLocation(product_unit_uuid, product_unit_code, type) {
      var that = this;
      let geolocation = new window.BMap.Geolocation();
      geolocation.getCurrentPosition(
        function (r) {
          var location =
            r.address.province +
            " " +
            r.address.city +
            " " +
            r.address.district +
            " " +
            r.address.street;
          //console.log(location)
          that.updateProduct(
            product_unit_uuid,
            product_unit_code,
            type,
            location
          );
        },
        function (e) {
          console.log(e);
          geolocation.getCurrentPosition(
            function (r) {
              var location =
                r.address.province +
                " " +
                r.address.city +
                " " +
                r.address.district +
                " " +
                r.address.street;
              //console.log(location)
              that.updateProduct(
                product_unit_uuid,
                product_unit_code,
                type,
                location
              );
            },
            function (e) {
              console.log(e);
              that.updateProduct(product_unit_uuid, product_unit_code, type);
            },
            { provider: "baidu" }
          );
        },
        { provider: "baidu" }
      );
    },
    updateProduct(
      product_unit_uuid,
      product_unit_code,
      type,
      location = "不支持定位"
    ) {
      let that = this;
      service
        .post("v1/trace/traceability", {
          product_unit_uuid,
          product_unit_code,
          type,
          location,
        })
        .then((res) => {
          //console.log(res)
          that.loading = false;
          if (res.code == 0) {
            if (res.data.product_introduction) {
              this.getProductActivity(res.data.product_introduction.product_id);
            }

            res.data.trace_info.map((item) => {
              //console.log(item.extra)
              if (
                item.extra != null &&
                Object.getOwnPropertyNames(item.extra).length > 0
              ) {
                item.extra = JSON.parse(item.extra);
              }

              switch (item.type) {
                case 10:
                  item.type_name = "原材料";
                  break;
                case 20:
                  item.type_name = "生产";
                  break;
                case 30:
                  item.type_name = "加工";
                  break;
                case 40:
                  item.type_name = "仓储";
                  break;
                case 50:
                  item.type_name = "物流";
                  break;
                case 60:
                  item.type_name = "渠道";
                  break;
                case 70:
                  item.type_name = "门店";
                  break;
                case 80:
                  item.type_name = "用户";
                  break;
                case 90:
                  item.type_name = "召回";
                  break;
              }

              item.created_time = that.getTime(item.created_at);
            });
            that.info = res.data;
            // if (res.data.trace_info.length > 5) {
            //   that.traceList = res.data.trace_info.slice(0, 5);
            // } else {
            that.traceList = res.data.trace_info;
            // }
            if (res.data.product_extra) {
              that.productList = JSON.parse(res.data.product_extra);
            }
          } else {
            console.log("Error");
          }
        });
    },
    showMoreTrace() {
      //   if (this.info.trace_info.length - this.traceList.length > 10) {
      // this.traceList = this.traceList.concat(
      //   this.info.trace_info.slice(
      //     this.traceList.length,
      //     this.traceList.length + 10
      //   )
      // );
      //   } else {
      // this.traceList = this.traceList.concat(
      //   this.info.trace_info.slice(this.traceList.length)
      // );
      //   }
      // this.traceList = this.traceList.concat(this.info.trace_info)
    },
    showPromotions() {
      this.showPromotionsFlag = !this.showPromotionsFlag;
    },
    showQualifications() {
      this.showQualificationsFlag = !this.showQualificationsFlag;
    },
    showProductIntroduction() {
      this.showProductIntroductionFlag = !this.showProductIntroductionFlag;
    },
    getTime(time) {
      if (!time || time == "") {
        return "";
      }
      var date = new Date(parseInt(time) * 1000);
      const Y = date.getFullYear() + "-";
      const M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1) + "-"
          : date.getMonth() + 1 + "-";
      const D =
        date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
      const h =
        date.getHours() < 10
          ? "0" + date.getHours() + ":"
          : date.getHours() + ":";
      const m =
        date.getMinutes() < 10
          ? "0" + date.getMinutes() + ":"
          : date.getMinutes() + ":";
      const s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      time = Y + M + D + h + m + s;

      return time;
    },
    callPhone(phoneNumber) {
      window.location.href = "tel://" + phoneNumber;
    },
    getProductActivity(id) {
      var data = {
        created_at: 0,
        creator_id: 0,
        customer_id: 0,
        end_time: 0,
        id: 0,
        industry_id: 0,
        name: "",
        page_number: 0,
        page_size: 0,
        product_batch_id: 0,
        product_id: id,
        start_time: 0,
        state: 0,
        updated_at: 0,
      };
      service.post("v1/product/activity/all", data).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.activityList = res.data;
        } else {
          console.log("Error");
        }
      });
    },
    showProductActivity() {
      this.showProductActivityFlag = !this.showProductActivityFlag;
    },
    showTraceList() {
      this.showTraceListFlag = !this.showTraceListFlag;
    },
  },
};
</script>

<style lang="less" scoped>
// @function toRem(@n) {
//     return @n / 37.5
// }
.txt_l {
  width: 25%;
}
.txt {
  width: 75%;
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.syBg_box {
  width: calc(100vw * 355 / 375);
  // background-color: pink;
  background-image: url("../../assets/img/mubanBg.jpeg");
  border-radius: 0.23rem;
  // padding:0 0 0.5rem 0;
  padding: 0.4rem 0;
  // height: 400px;
  display: flex;
  // align-items: center;
  // justify-content: center;
  position: absolute;
  top: 0.5rem;
}
.circle_box {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.circle {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: 10px solid #e68f97;
  // background-color: #e51224;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle_conmon {
  width: 88%;
  height: 88%;
  border-radius: 50%;
  // background-color: rgba(255, 255, 255, 0.19);
  background-color: rgba(255, 255, 255, 0.5);
  color: #016545;
  font-weight: 600;
  font-size: 0.52rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.circle_text {
  color: #016545;
  margin-top: 60px;
  font-size: 0.3rem;
}
.syBg_contant_planb {
  width: 90%;
  padding: 0.525rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.syBg_contant {
  width: 70%;
  display: flex;
  padding-left: 0.25rem;
  flex-direction: column;
  justify-content: center;
}
.syBg_top{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // padding:0 0 0.2rem 0;
}
.tips {
  font-size: 0.38rem;
  color: #016545;
}
.product_name {
  font-size: 0.48rem;
  color: #016545;
  font-weight: 600;
}
.code_box {
  margin-top: 0.1rem;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 0.1rem;
  // width: 85%;
  margin-left: -0.1rem;
}
.code_box_planb {
  margin-top: 0.25rem;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 0.1rem;
  width: 50%;
}
.code_tit_planb {
  font-size: 0.35rem;
  color: #3f694f;
}
.code_tit {
  padding: 0rem 0 0 0.2rem;
  font-size: 0.35rem;
  color: #016545;
}
.sy_code {
  font-size: 0.35rem;
  color: #76bc20;
  padding: 0 0rem 0.2rem 0.2rem;
}
.sy_code_planb {
  font-size: 0.35rem;
  color: #76bc20;
  padding: 0.1rem 0rem 0.1rem 0.2rem;
}
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.contant_box {
  width: 100vw;
  // height: 65vh;
  // background-color: #87bf1c;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  margin-top: 37vh;
  // top: 6.2rem;
}
.flag_box {
  width: calc(100vw * 355 / 375);
  // background-color: #87bf1c;
  border: 1px solid #016545;
  background-color: rgba(255, 255, 255, 0.75);
  // padding-bottom: 1rem;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main_tit {
  width: 2.7rem;
  color: #fff;
  font-size: 0.48rem;
  // background-color: #fff;
  background-color: #45982f;
  border-radius: 0.45rem;
  text-align: center;
  padding: 0.1rem 0;
  margin-top: 0.25rem;
  font-weight: 600;
}
.message_part {
  width: calc(100vw * 335 / 375);
  // background-color: red;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // color: #fff;
  height: 38vh;
  color: #016545;
  font-size: 0.39rem;
  overflow-y: scroll;
}
.row_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.15rem;
}
.contant_title_planb {
  width: 100vw;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: 10vh;
  // margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  justify-content: center;
  font-size: 0.42rem;
  font-weight: 500;
}
.tit_box_planb {
  width: calc(100vw * 335 / 375);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  // overflow-x: scroll;
  // -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}
.tit_planb {
  width: 1.5rem;
  height: 1.5rem;
  // background-color: #fff;
  background-color: #45982f;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.imgBg {
  // width: 800px;
  // height: 6rem;
  max-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  > .img_wrap {
    border-radius: 50%;
    border: 15px solid #fff;
    // #3C2F30  60,47,48
    // width: 100%;
    // max-height: 50vh;
    // overflow: hidden;

    // img {
    //     width: 100%;
    // }
  }
}
.imgBg::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  background-size: 100%;
  background-image: url("../../assets/img/bg.jpg");
  -webkit-filter: blur(0.07rem);
  -moz-filter: blur(0.07rem);
  -ms-filter: blur(0.07rem);
  filter: blur(0.07rem);
  transform: scale(1.03);
}
/deep/ .van-loading__text {
  font-size: 0.436rem !important;
}
.contant_title {
  width: 100vw;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  font-size: 0.42rem;
  font-weight: 500;
}
.tit_box {
  width: calc(100vw * 355 / 375);
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}
.tit {
  height: 1rem;
  padding: 0 0.33rem;
  line-height: 1rem;
  //   margin-right: 0.2rem;
  white-space: nowrap;
  color: #999;
}
.ac {
  color: #fff;
  background-color: #3869b8;
  border-radius: 0.45rem;
}
.contant_region {
  width: calc(100vw * 355 / 375);
  height: 70vh;
  top: 0;
  left: 0;
  bottom: -25vh;
  right: 0;
  margin: auto;
  z-index: 10;
  border: 1px solid #016545;
  // background-color: rgba(255, 255, 255, 0.7);
  background-color: rgba(194, 225, 193, 0.9);
  border-radius: 15px;
  position: absolute;
  display: flex;
  justify-content: center;
}

.activity-list {
  font-size: 0.2rem;
  padding: 0.5rem 0 0.5rem 0;
  margin: 0 0 0.5rem 0;
  border-bottom: 1px dashed #a8a8a8;
  .title {
    padding: 0.25rem 0;
    font-size: 0.38rem !important;
    line-height: 0.4rem;
    color: #979797 !important;
  }

  .time {
    padding: 0 0 0.25rem 0;
    font-size: 0.35rem;
    color: #a8a8a8;
  }

  .cover {
    width: 100%;

    img {
      max-width: 100%;
    }
  }

  .content {
    font-size: 0.35rem;

    img {
      max-width: 100%;
    }
  }

  .url {
    padding: 0.8rem 0 0.5rem 0;
    text-align: center;

    a {
      background-color: #d70000;
      border-radius: 1rem;
      padding: 0.3rem 0.8rem;
      color: #fff;
      font-size: 0.3rem;
    }
  }
}

.activity-list:last-child {
  border-bottom: none;
  margin: 0;
  padding: 0;
}
.hp-div {
  margin: 0.2rem;
  color: #6e6e6e;
  font-size: 0.384rem !important;
  line-height: 0.6rem;
}
.region_box {
  width: calc(100vw * 305 / 375);
  height: 100%;
  // height: 10rem;
  // background: pink;
  // border-radius: 25.6px;
  // overflow-y: scroll;
  .tit_close {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.5rem;
    height: 5%;
    .dialog_tit {
      color: #000;
      font-size: 0.38rem;
    }
    .close_menu {
      width: 0.55rem;
      height: 0.55rem;
    }
  }

  .info-list {
    padding: 0.25rem 0.2rem;
    border-bottom: 1px solid rgb(239, 239, 239);
    .right-label {
      color: #6e6e6e;
      opacity: 0.9;
      font-size: 38.4px;
      flex: 1;
      line-height: 61.5px;
      word-break: break-all;
    }

    .right-img {
      width: 100%;
      // border-radius: 25.6px;
    }
  }

  .info-list:last-child {
    border-bottom: none;
  }
  .left-label {
    width: 190px;
    font-size: 35.9px;
    color: 313131;
    opacity: 0.5;
    line-height: 61.5px;
  }
}

.page {
  width: 100vw;
  background: rgb(239, 239, 239);
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  min-height: 100vh;
  overflow: hidden;
}

.inner_page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  // box-sizing: border-box;
  // background-image: url(/src/assets/img/11.jpg);
  // background-size: 100% 100%;
  // padding-bottom: 51.3px;
  overflow: hidden;
  // .common-module {
  //   width: calc(100vw * 355 / 375);
  //   margin-top: 25.6px;
  //   padding: 51.3px 38.4px;
  //   background: rgb(255, 255, 255);
  //   border-radius: 25.6px;
  //   box-sizing: border-box;

  //   .info-list {
  //     //   padding: 56.4px 0;
  //     border-bottom: 1px solid rgb(239, 239, 239);
  //   }
  //   .info-list > div {
  //     display: flex;
  //     justify-content: space-between;
  //     margin-top: 25.6px;

  //     &:first-of-type {
  //       margin-top: 0;
  //     }
  //   }

  //   .left-label {
  //     width: 190px;
  //     font-size: 35.9px;
  //     color: 313131;
  //     opacity: 0.5;
  //     line-height: 61.5px;
  //   }

  //   .right-label {
  //     color: #6e6e6e;
  //     opacity: 0.9;
  //     font-size: 38.4px;
  //     flex: 1;
  //     line-height: 61.5px;
  //     word-break: break-all;
  //   }

  //   .right-img {
  //     width: 100%;
  //     border-radius: 25.6px;
  //   }
  // }

  // .module1 {
  //   .title {
  //     font-size: 43.6px;
  //     font-weight: 700;
  //     line-height: 61.5px;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     white-space: nowrap;
  //   }

  //   .info-list {
  //     //   margin-top: 43.6px;
  //   }
  // }

  // .module3 {
  //   padding: 33.3px 38.4px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   font-size: 43.6px;
  //   font-weight: 400;
  //   color: #505050;

  //   > img {
  //     margin-right: 25.6px;
  //   }
  // }

  // .module4,
  // .module5,
  // .module6 {
  //   .title {
  //     font-size: 43.6px;
  //     font-weight: 500;
  //     line-height: 61.5px;
  //   }

  //   .info-container {
  //     overflow: hidden;

  //     &.hide {
  //       height: 0;
  //     }

  //     &.show {
  //       height: auto;
  //       border-top: 1px solid rgb(239, 239, 239);
  //       margin-top: 50px;
  //     }

  //     .hp-div {
  //       padding-top: 0.2rem;
  //       color: #6e6e6e;
  //       font-size: 0.384rem !important;
  //       line-height: 0.6rem;
  //     }
  //   }

  //   .info-list {
  //     padding: 56.4px 0;
  //     border-bottom: 1px solid rgb(239, 239, 239);
  //   }

  //   .info-list:last-child {
  //     border-bottom: none;
  //   }

  //   .show-more-img {
  //     width: 42px;
  //     position: relative;
  //     float: right;
  //     margin-top: 4px;
  //     transition: transform 0.5s;

  //     &.show {
  //       transform: rotate(180deg);
  //     }

  //     &.hide {
  //       transform: rotate(0);
  //     }
  //   }
  // }

  // .module6 {
  //   padding-bottom: 0;
  // }

  // .show-more-box {
  //   margin-top: 50px;
  //   margin-bottom: 40px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;

  //   .show-more {
  //     font-size: 0.35rem;
  //     background-color: #d1d1d1;
  //     border-radius: 100px;
  //     padding: 0.25rem 0.75rem;

  //     > img {
  //       margin-left: 20px;
  //     }
  //   }
  // }
}

.activity-list {
  font-size: 0.2rem;
  padding: 0.5rem 0 0.5rem 0;
  margin: 0 0 0.5rem 0;
  border-bottom: 1px dashed #a8a8a8;
  .title {
    padding: 0.25rem 0;
    font-size: 0.38rem !important;
    line-height: 0.4rem;
    color: #979797 !important;
  }

  .time {
    padding: 0 0 0.25rem 0;
    font-size: 0.35rem;
    color: #a8a8a8;
  }

  .cover {
    width: 100%;

    img {
      max-width: 100%;
    }
  }

  .content {
    font-size: 0.35rem;

    img {
      max-width: 100%;
    }
  }

  .url {
    padding: 0.8rem 0 0.5rem 0;
    text-align: center;

    a {
      background-color: #d70000;
      border-radius: 1rem;
      padding: 0.3rem 0.8rem;
      color: #fff;
      font-size: 0.3rem;
    }
  }
}

.activity-list:last-child {
  border-bottom: none;
  margin: 0;
  padding: 0;
}
</style>
<style>
/* .hp-div {
  margin: 0 !important;
}

.hp-div p {
  margin: 0 !important;
} */

.hp-loading {
  padding: 40vh 0 0 0;
}
</style>
