<template>
    <div>
      <router-view/>
      <!-- <router-view v-slot="{ Component }">
        <keep-alive :include="include">
          <component :is="Component" />
        </keep-alive>
      </router-view>
      <img v-show="$route.name!='Login'" class="customerService" @click="toChat" src="@/assets/img/customerService.png" alt="">
      <van-tabbar v-model="active" v-show="pathUnTabArr.indexOf($route.name)<=-1 && (!isVistor)">
        <van-tabbar-item icon="question-o" name="CallBack" to="/home/callBack">问题反馈</van-tabbar-item>
        <van-tabbar-item icon="smile-o" name="MyService" to="/home/myService">我的服务</van-tabbar-item>
      </van-tabbar> -->
    </div>
</template>
<script>
export default({
    data() {
        return {
            active: 'CallBack',
            pathUnTabArr: [
              'Login',
              'SearchPageC',
              'SubmitCbC',
              'MyCalbackC',
              'CbDetailsC',
              'Demo'
            ],
            include: 'SubmitCbC', // 不使用keepAlive的组件，使用‘,’分隔，如：Home,News
            //isVistor: JSON.parse(localStorage.getItem('userMsg')).user_type === 'vistor'
            isVistor:false
        }
    },
    methods: {
      toChat() {
        console.log('开始聊天')
      }
    }
})
</script>
<style lang="less" scoped>
@import '@/assets/style/color.less';
.van-tabbar{
    border-top: 1px solid @color_border_f5;
}
.customerService{
  position: fixed;
  right: 16px;
  bottom: 150px;
  width: 40px;
  height: 40px;
}
</style>
